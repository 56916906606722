<!--
* 创建人：罗兴
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div class="container">
    <div v-show="!isedit">
      <div class="page-tool">
        <div style="width: 80%">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-cascader
                style="width: 100%"
                v-model="CategoryId"
                :options="categoryList"
                :props="{
                  label: 'title',
                  value: 'id',
                  checkStrictly: true,
                  emitPath: false,
                }"
                size="small"
                clearable
              ></el-cascader>
            </el-col>
            <el-col :span="12" style="display: flex">
              <el-input
                v-model="keyword"
                placeholder="请输入标题"
                size="small"
                style="margin-right: 10px; width: 200px"
              ></el-input>
              <el-button
                type="primary"
                icon="el-icon-search"
                size="small"
                @click="getDataPageList"
                >搜索</el-button
              >
            </el-col>
          </el-row>
        </div>
        <el-button-group>
          <el-button
            size="small"
            icon="el-icon-refresh"
            @click="reload()"
          ></el-button>
          <el-button
            size="small"
            v-if="hasPerms('sy_add')"
            icon="el-icon-plus"
            @click="showAdd"
            >新增</el-button
          >
          <el-button
            size="small"
            v-if="hasPerms('sy_edit')"
            icon="el-icon-edit"
            @click="showEdit"
            >编辑</el-button
          >
          <el-button
            size="small"
            v-if="hasPerms('sy_delete')"
            icon="el-icon-delete"
            @click="delInfo"
            >删除</el-button
          >
        </el-button-group>
      </div>
      <!--=============================表格========================================-->
      <div class="c_table">
        <el-table
          v-loading="tableloading"
          :data="dataPageList"
          :height="windowHeight - 270"
          border
          style="width: 100%"
          header-cell-class-name="tableStyle"
          highlight-current-row
          @current-change="currentRowChange"
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '5px' }"
        >
          <el-table-column prop="title" label="标题"> </el-table-column>
          <el-table-column prop="lbmc" label="所属类别"> </el-table-column>
          <el-table-column prop="updateTime" label="发布时间">
            <template slot-scope="scop">
              {{ dateFormat2(scop.row.updateTime) }}
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="排序"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="showEdit2(scope.row)" type="text" size="small"
                >编辑</el-button
              >
              <el-button
                @click="deletearticle(scope.row)"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--=======================================分页================================================-->
        <div class="pagebox">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pagination.page"
            :page-size="pagination.rows"
            layout="total, prev, pager, next, jumper"
            :total="pagination.records"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div
      v-if="isedit"
      style="width: 100%; padding: 30px; box-sizing: border-box"
    >
      <el-scrollbar style="height: 800px">
        <el-form :model="forminfo" ref="formRef" :rules="forminfoRules">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="所属类别"
                label-width="130px"
                prop="CategoryId"
              >
                <el-cascader
                  style="width: 100%"
                  v-model="forminfo.CategoryId"
                  :options="categoryList"
                  :props="{
                    label: 'title',
                    value: 'id',
                    checkStrictly: true,
                    emitPath: false,
                  }"
                  clearable
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否发布">
                <el-radio-group v-model="forminfo.Status">
                  <el-radio :label="1">发布</el-radio>
                  <el-radio :label="0">不发布</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="内容标题" label-width="130px" prop="Title">
                <el-input
                  v-model="forminfo.Title"
                  autocomplete="off"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="排序" label-width="130px" prop="Sort">
                <el-input-number v-model="forminfo.Sort"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="浏览次数" label-width="130px" prop="Click">
                <el-input-number v-model="forminfo.Click"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="发布时间" label-width="130px" prop="Click">
                <el-date-picker
                  v-model="forminfo.UpdateTime"
                  type="datetime"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="URL链接" label-width="130px" prop="LinkUrl">
                <el-input
                  v-model="forminfo.LinkUrl"
                  autocomplete="off"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="信息来源" label-width="130px" prop="Source">
                <el-input
                  v-model="forminfo.Source"
                  autocomplete="off"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="作者" label-width="130px" prop="Author">
                <el-input
                  v-model="forminfo.Author"
                  autocomplete="off"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="封面图片" label-width="120px" class="mb10">
                <el-upload
                  class="upload-demo"
                  :headers="headers"
                  :action="uploadurl"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :on-success="handleSuccess"
                  :before-remove="beforeRemove"
                  :limit="1"
                  :on-exceed="handleExceed"
                  :file-list="fileList"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">
                    图片类型包括：jpg，png
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="width: 89%; position: relative">
            <vue-ueditor-wrap
              style="margin: 10px"
              v-model="forminfo.Content"
              :config="editorConfig"
              editor-id="editor-demo-01"
            ></vue-ueditor-wrap>
          </div>
        </el-form>
      </el-scrollbar>
      <div class="bottombtn">
        <el-row>
          <el-button type="primary" @click="saveForm">提交保存</el-button>
          <el-button type="warning" @click="backlist">返回上一页</el-button>
        </el-row>
      </div>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
import {
  saveArticle,
  getCategoryTree,
  getPageList,
  getArticleInfo,
  deleteInfo,
} from '../../api/news'
import { getAuthorizeButtonColumnList } from '../../api/system'
import { baseUrl } from '../../utils/http'
import store from '../../store/index'
import moment from 'moment'
export default {
  components: {
    VueUeditorWrap,
  },
  data() {
    return {
      downurl: baseUrl,
      headers: null,
      uploadurl: baseUrl + 'File/upload',
      fileList: [],
      isedit: false,
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      categoryList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      dialogFormVisible: false, // 是否显示弹出框
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      CategoryId: '',
      // 表单验证规则对象
      forminfoRules: {
        Title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
        CategoryId: [
          { required: true, message: '请选择类别', trigger: 'blur' },
        ],
      },
      forminfo: {
        ID: '',
        CategoryId: '',
        Title: '',
        LinkUrl: '',
        ImgUrl: '',
        ZhaiYao: '',
        Content: '',
        Sort: 99,
        Click: 0,
        Status: 1,
        Source: '',
        Author: '',
        UpdateTime: '',
      },
      plist: [],
      msg: '<h2>Hello World!</h2>',
      editorConfig: {
        // 访问 UEditor 静态资源的根路径，可参考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
        UEDITOR_HOME_URL: '/UEditor/',
        // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: 'https://xggl.stbu.edu.cn/' + 'api/UEditor',
        headers: { Authorization: 'Bearer ' + store.state.token.access_token },
        // serverUrl: baseUrl + 'File/UEditorUpload',
      },
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    getCategoryTree({
      queryJson: JSON.stringify(),
    }).then((res) => {
      if (res.code === 200) {
        this.categoryList = res.data
      }
    })
  },
  methods: {
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.forminfo.ID = ''
      } else {
        this.forminfo.ID = this.keyValue
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveArticle(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.isedit = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取信息
    getDataPageList() {
      this.tableloading = true
      getPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          CategoryId: this.CategoryId,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1
      this.isedit = true
      this.dialogFormVisible = true
    },
    backlist() {
      this.operation = 0
      this.isedit = false
      this.dialogFormVisible = false
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
      }
    },
    showEdit2(row) {
      this.keyValue = row.id
      this.operation = 0
      getArticleInfo({ id: row.id }).then((res) => {
        if (res.code === 200) {
          this.forminfo.ID = res.data.id
          this.forminfo.CategoryId = res.data.categoryId
          this.forminfo.Title = res.data.title
          this.forminfo.LinkUrl = res.data.linkUrl
          this.forminfo.ImgUrl = res.data.imgUrl
          this.forminfo.ZhaiYao = res.data.zhaiYao
          this.forminfo.Content = res.data.content
          this.forminfo.Sort = res.data.sort
          this.forminfo.Click = res.data.click
          this.forminfo.Status = res.data.status
          this.forminfo.Source = res.data.source
          this.forminfo.Author = res.data.author
          this.forminfo.UpdateTime = res.data.updateTime
        }
      })
      this.isedit = true
    },
    delInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
      }
    },
    deletearticle() {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteInfo({ id: this.keyValue }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        })
        .catch(() => {})
    },
    handleRemove(file, fileList) {
      var index = -1
      for (var i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].name === file.name) {
          index = i
          break
        }
      }
      if (index >= 0) {
        this.fileList.splice(index, 1)
      }
    },
    handlePreview(file) {
      var id = ''
      if (file.response) {
        id = file.response.data.id
      } else {
        id = file.id
      }
      window.open(baseUrl + 'File/download?id=' + id)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess(response, file, fileList) {
      this.fileList.push(fileList[0])
      this.forminfo.ImgUrl = baseUrl + response.data.filePath
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.c_table {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #303030;
  font-weight: 700;
  padding: 5px 0;
}
.bottombtn {
  position: absolute;
  bottom: 10px;
}
</style>>
