import {
  post, get
} from '../utils/http';

// 提交新闻类别保存
export function saveCategory(data) {
  return post('news/SY_News_Category/Save/', data);
}

// 获取栏目列表
export function getCategoryTree(data) {
  return get('news/SY_News_Category/GetTreeList/', data);
}

// 根据ID获取信息
export function getCategoryInfo(data) {
    return get('news/SY_News_Category/GetFormData/', data);
}

// 获取新闻分页列表信息
export function getPageList(data) {
  return get('news/SY_News_Article/GetPageList/', data);
}

// 提交新闻类别保存
export function saveArticle(data) {
  return post('news/SY_News_Article/Save/', data);
}

// 根据ID获取信息
export function getArticleInfo(data) {
    return get('news/SY_News_Article/GetFormData/', data);
}

// 删除信息
export function deleteInfo(data) {
  return get('news/SY_News_Article/Delete/', data);
}
